import React from 'react';
import './AppInfo.css';
import appIcon from './appicon.png';

const AppInfo: React.FC = () => {
  return (
    <section className="app-info">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-4 text-center">
            <img src={appIcon} alt="Lunadar App Icon" className="rounded img-fluid app-icon" />
          </div>
          <div className="col-md-8 my-3">
            <h2>About Lunadar</h2>
            <p>Explore lunar dates and events with our intuitive calendar app. Lunar Calendar focuses on displaying lunar dates and the number of days in a lunar month.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AppInfo;
